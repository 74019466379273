import fetch from "cross-fetch"
import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client"

const link = createHttpLink({
  uri: "https://wp.sictomhautjura.fr/graphql/",
  fetch,
  credentials: "include"
})

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
})
